<template>
  <div class="moneytab">
    <a :class="{'on': routeName == 'qna'}" @click="goPageByName('qnaList')"><span>{{$t('front.common.qna')}}</span></a>
    <a :class="{'on': routeName == 'notice'}" @click="goPageByName('noticeList')"><span>{{$t('front.common.notice')}}</span></a>
    <a :class="{'on': routeName == 'faq'}" @click="goPageByName('faq')"><span>{{$t('front.common.faq')}}</span></a>
    <!--a :class="{'on': routeName == ''}" href=".html"><span>계좌문의</span></a>
    <a :class="{'on': routeName == 'rule'}" @click="goPageByName('rule')"><span>베팅규정</span></a-->
  </div>
</template>

<script>
export default {
  name: 'CsNavMobile',
  created () {
    this.changeRoute()
  },
  watch: {
    '$route.name': {
      handle () {
        this.changeRoute()
      }
    }
  },
  data () {
    return {
      routeName: ''
    }
  },
  methods: {
    changeRoute () {
      if (this.$route.name.indexOf('qna') > -1) {
        this.routeName = 'qna'
      }
      if (this.$route.name.indexOf('faq') > -1) {
        this.routeName = 'faq'
      }
      if (this.$route.name.indexOf('notice') > -1) {
        this.routeName = 'notice'
      }
      if (this.$route.name.indexOf('rule') > -1) {
        this.routeName = 'rule'
      }
    }
  }
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
